import React, { useEffect } from "react";
import IDVC from '@idscan/idvc2'
import  '@idscan/idvc2/dist/css/idvc.css'

export default function App(){
  const [customer, setCustomer] = React.useState(null);
  const [totalTime, setTotalTime] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  useEffect(() => {
    let startTime;
    let idvc = new IDVC({
      el: "videoCapturingEl",
      licenseKey: `eyJwZGY0MTdrZXkiOiJjbjNtSzVJQTM0ZEJrcEVMN3B4Y0NBQWJ3c1dkQU1tZXJoM1NrR05mS2NRdlBVQVRMYkdRSDVRd3BMQ0tHYVoxVDdKaDNEeEtxQnJFUXRaQjlMNS9HV3R2MFJ2VGlPTThoXHUwMDJCVlNGb3FPOVR4d1pyZ0RmZ2wyNGZlYTF5U0lsVnhMMHdaMzZJcmhrci9DbWgwRXllTXNrZ0dyaE0yUEtEbVBPdTNjeWhPd1REVT0iLCJjb21tb25MaWNlbnNlS2V5IjoiYlo3RTdIdEFNM3dUZUlCOGdcdTAwMkJRZ2tHbEo3SkVhMmhJakMyQXVRbTVMNWVsbXZnR3VBMTNmRXlpTE9SZDFOQ2NGYlNPbkZLRmFLWVh0YnBDWHkvY1IzckFicGp3dEkvNTJmc3Q2Nmk3RzBrQkxMNFVCbUNSdlI2VTNCdlRWXHUwMDJCTDBab1VaY1pNdm1JaHZGQ2hXOEdBQ0tmcUc2dEtyMTFEa0xVRHprYlllaTdTYz0iLCJpbWFnZVByb2Nlc3NpbmdLZXkiOiJQR0VnNXZsTkVsNVJzS0NSMWx4cVdXL2JuVHd2a096ZFVuMFY0TkU3UmtqR2lOeUEwUlRHWWttajFGVFUxeWpMeGlOSTIvNkhSRTdIbjVGam9nVlpaSmdCQzNicWhqNnVmL3RZbEFqMk5abi9qaVFZWENUWTloVDlNMUpUMjBWeWIwXHUwMDJCeE55eVJBZzVzcEF6eFZDakpnNlR3MW5SRUpRWHZVL1JWcDU0R3Jkcz0iLCJ0cmFja1N0cmluZ1BhcnNlcktleSI6IlFzdDN5dW1PNk5waVQ3Rld0ejJqZ254bERabHRyRDhEaHpEazFJU3FtQVNxZTFYc0R2L2pvOVYxajFGM0ptZnQ5djlieU1JWnN1ZWNDMXVtaldQOU5pUk9JdWZcdTAwMkJzSzhicmlGS004QVBDdFJCS0JSZ2UvSTN3VG5IdFV5dlpSNlVUWUp2OUxtRnRTTEdNeWZ2QmVaSU1qaU9TWnVrRlAwSXQwM2RZdzVSXHUwMDJCcm89IiwiaU9TTVJaRGV0ZWN0b3JLZXkiOiJwZERuTGZhL2R3SGRXdjdvXHUwMDJCT2xyVE1CeUcyc2h2a0VRejNuMzY3TUFUa2lPbHRBWjVTTlRHMjZtUjdZaWtZTFdmcTFNcW5mSmxTTnY1UG1cdTAwMkJFcGI1c0hPNk4ySEVGOG5KTGhRcXhNVjVzejJ0bGFqUzFzZldiZnlFSTB6Z1V5YzR3cWVkRUN0Q3BQby9PcVQ2MHlzTVk4QURDSVlobUkwbkVQZ3p4WTZmVEM4PSIsImlPU1BERkRldGVjdG9yS2V5IjoiKksuTGdBYUNpSm5JZkp1RW5BZEhzRS5MY0xvS21Jd0YifQ==`,
      // networkUrl: "networks",
      // chunkPublicPath: "networks",
      resizeUploadedImage: 1200,
      fixFrontOrientAfterUpload: true,
      autoContinue: true,
      isShowDocumentTypeSelect: false,
      useCDN: true,
      isShowGuidelinesButton: true,
      isSubmitMetaData: false,
      useHeic: true,
      showSubmitBtn: false,
      hideDocumentTitle: false,
      language: "en",
      realFaceMode: "auto",
      modalPosition: 'top',
      processingImageFormat: 'jpeg',
      documentTypes: [
        {
          type: "ID",
          steps: [
            {
              type: "pdf",
              name: "Document Back",
            },
          ],
        },
        // {
        //   type: "Passport",
        //   steps: [
        //     {
        //       type: "mrz",
        //       name: "Passport Front",
        //     },
        //   ],
        // },
        // {
        //   type: "PassportCard",
        //   steps: [
        //     {
        //       type: "mrz",
        //       name: "Passport Card Back",
        //     },
        //   ],
        // },
        // {
        //   type: "InternationalId",
        //   steps: [
        //     {
        //       type: "mrz",
        //       name: "International ID Back",
        //     },
        //   ],
        // }
      ],
      onMounted: function () {
        console.log("IDVC mounted");
        setCustomer(null);
      },
      submit(data) {
        idvc.showSpinner(true);
        let frontStep, pdfStep, faceStep, mrzStep;
        let frontImage, backImage, faceImage;
        let captureMethod;
        let rawTrackString;
        switch (data.documentType) {
          // Drivers License and Identification Card
          case 1:
            frontStep = data.steps.find((item) => item.type === "front");
            pdfStep = data.steps.find((item) => item.type === "pdf");
            faceStep = data.steps.find((item) => item.type === "face");

            // frontImage = frontStep.img.split(/:image\/(jpeg|png);base64,/)[2];
            backImage = pdfStep.img.split(/:image\/(jpeg|png);base64,/)[2];
            // faceImage = faceStep.img.split(/:image\/(jpeg|png);base64,/)[2];

            rawTrackString =
              pdfStep && pdfStep.trackString ? pdfStep.trackString : "";

            captureMethod =
              // JSON.stringify(+frontStep.isAuto) +
              JSON.stringify(+pdfStep.isAuto)
            // JSON.stringify(+faceStep.isAuto);

            break;
          // US and International Passports
          case 2:
            mrzStep = data.steps.find((item) => item.type === "mrz");
            faceStep = data.steps.find((item) => item.type === "face");

            // frontImage = mrzStep.img.split(/:image\/(jpeg|png);base64,/)[2];
            // faceImage = faceStep.img.split(/:image\/(jpeg|png);base64,/)[2];

            rawTrackString = mrzStep && mrzStep.mrzText ? mrzStep.mrzText : "";

            captureMethod =
              JSON.stringify(+mrzStep.isAuto)
            // JSON.stringify(+faceStep.isAuto);

            break;
          default:
        }
        const trackStringArray = rawTrackString.split(".");
        let trackString = trackStringArray[0];
        let barcodeParams = trackStringArray[1];
        let request = {
          // frontImageBase64: frontImage,
          backOrSecondImageBase64: backImage,
          // faceImageBase64: faceImage,
          documentType: data.documentType,
          trackString:{
            data:  trackString,
            barcodeParams: barcodeParams
          },
          overriddenSettings: {
            isOCREnabled: false,
            isBackOrSecondImageProcessingEnabled: true,
            isFaceMatchEnabled: false
          },
          metadata: {
            captureMethod: captureMethod
          }
        };
        startTime = new Date().getTime();
        setLoading(true);
        fetch("https://dvs2.idware.net/api/v4/verify", {
          method: "POST",
          headers: {
            Authorization: "Bearer sk_4a10b405-eefd-4422-bb9e-5afb78fdabe3",
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(request),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            setData(data);
            setLoading(false);
            idvc.showSpinner(false);
            setCustomer(data.document);
            const endTime = new Date().getTime();
            const calculatedTime = (endTime - startTime) / 1000;
            setTotalTime(calculatedTime);
          })
          .catch((err) => {
            idvc.showSpinner(false);
            console.error(err);
            setLoading(false);
          });
      },
    });
  }, []);

  return (
    <div style={{maxWidth: `500px`, maxHeight: '500px', margin: 'auto'}}>
      {loading && (
        <div className="vc-loading-mask" id="VC-loading">
          <div className="el-loading-spinner" id="idvc-loading-spinner">
            <p className="el-loading-text" id="VC-loading-text-top"></p>
            <svg viewBox="25 25 50 50" className="circular">
              <circle cx="50" cy="50" r="20" fill="none" className="path"></circle>
            </svg>
            <p className="el-loading-text" id="VC-loading-text-bottom"></p>
          </div>
        </div>
      )}

      <h3>DVS Demo Application</h3>
      <div id="videoCapturingEl"></div>
      <div style={{margin: "15px 0"}}>
        <strong>Scan Time:</strong> {totalTime?.toFixed(2)} seconds
      </div>
      <div>
        Customer Information:
        <pre>{JSON.stringify(customer, null, 2)}</pre>
      </div>
    </div>
  );
}
